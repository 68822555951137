import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Location, NgIf } from '@angular/common';
import { AuthService } from '../core/services/auth.service';
import { finalize, of, ReplaySubject, takeUntil, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
	selector: 'app-you-are-offline',
	standalone: true,
	imports: [
		MatButton,
		MatProgressSpinner,
		NgIf
	],
	templateUrl: './you-are-offline.component.html',
	styleUrl: './you-are-offline.component.scss'
})
export class YouAreOfflineComponent implements OnInit, OnDestroy {
	public seconds: number = 5;
	public isLoading: boolean;
	private interval: ReturnType<typeof setInterval>;
	private readonly _onDestroy$: ReplaySubject<void>;

  constructor(
		public location: Location,
		private _authService: AuthService
	) {
		this.isLoading = false;
		this._onDestroy$ = new ReplaySubject<void>(1);
	}

	public ngOnInit(): void {
		this._setInterval();
	}

	public ngOnDestroy(): void {
		clearInterval(this.interval);
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}

	private _checkConnection(): void {
		this.isLoading = true;
		clearInterval(this.interval);
		this._authService.login({ password: '', username: '', rc_token: '' }).pipe(
			catchError((err: HttpErrorResponse) => {
				if (err?.status === 0) {
					this.seconds = 5;
					this._setInterval();
					return throwError(err);
				} else {
					return of(null);
				}
			}),
			finalize(() => this.isLoading = false),
			takeUntil(this._onDestroy$)
		).subscribe(() => {
			this.location.back();
		})
	}

	private _setInterval(): void {
		this.interval = setInterval(() => {
			this.seconds--;
			if (this.seconds === 0) {
				this._checkConnection();
			}
		}, 1000)
	}

}
