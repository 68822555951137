<div class="container">
	<img src="assets/youareoffline.png" alt="Offline"/>
	<h1>You're offline</h1>
	<button mat-raised-button color="primary" (click)="location.back()">Go back</button>
	<h3 *ngIf="!isLoading; else spinner">Trying to reconnect in {{ seconds }} seconds ...</h3>
</div>

<ng-template #spinner>
	<mat-spinner [diameter]="30"></mat-spinner>
</ng-template>
