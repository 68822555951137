import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend-admin';

  constructor(private _translate: TranslateService) {
    this._setUserLanguage();
  }

  private _setUserLanguage(): void {
    const defaultLanguageCode: string = 'es';
    const availableLanguageCodes: string[] = ['en', 'es'];
    const userLanguage: string = navigator?.languages?.length ? navigator?.languages[0] : navigator?.language || '';
    const userLanguageCode: string = userLanguage.substring(0, 2);

    if (availableLanguageCodes.includes(userLanguageCode)) {
      this._translate.setDefaultLang(userLanguageCode);
      this._translate.use(userLanguageCode);
    } else {
      this._translate.setDefaultLang(defaultLanguageCode);
      this._translate.use(defaultLanguageCode);
    }
  }

}
