import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
	HttpResponse
} from '@angular/common/http';
import { map, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private snackBar: MatSnackBar,
	) {}

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			map((response: any) => {
				const successCodes: number[] = [200, 201, 204];
				if (response instanceof HttpResponse && !successCodes.includes(response.status)) {
					throw new HttpErrorResponse(response);
				}
				return response;
			}),
			catchError((err: HttpErrorResponse) => {
				const errorCode: number = err?.status;
						let errorText: string = `${errorCode || 'Unknown'} error`;
						if (errorCode === 401) {
							errorText = 'Forbidden. Please, log in';
						}
						this.snackBar.open(errorText, 'Got it', {
							duration: 3000,
							horizontalPosition: 'center',
							verticalPosition: 'top',
							panelClass: ['error-snackbar']
						});
				return throwError(err);
			})
		);
	}
}
