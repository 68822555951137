import { Routes } from '@angular/router';
import { RedirectComponent } from './redirect/redirect.component';
import { AuthGuard } from './core/guards/auth.guard';
import { YouAreOfflineComponent } from './you-are-offline/you-are-offline.component';

export const routes: Routes = [
	{ path: '', component: RedirectComponent, pathMatch: 'full' },
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
	},
  {
    path: 'reseller',
    loadChildren: () => import('./reseller/reseller.module').then(m => m.ResellerModule)
  },
	{
		path: 'workspace',
		loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule),
		canActivate: [AuthGuard]
	},
	{ path: 'you-are-offline', component: YouAreOfflineComponent }
];
