import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthService } from '../services/auth.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import {ResellerAuthService} from "../services/reseller-auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(
		private _authService: AuthService,
    private _resellerAuthService: ResellerAuthService,
		private _router: Router
	) {}

	public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
		const userToken: string = this._authService.token;
		const isApiUrl: boolean = request.url.startsWith(environment.apiUrl);
		if (isApiUrl && userToken) {
			request = request.clone({
				setHeaders: { Authorization: `Bearer ${userToken}` }
			});
		}

		return next.handle(request).pipe(
			catchError((error) => {
				const youAreOfflineUrl: string = 'you-are-offline';
				if (error?.status === 401 && !this._router.url?.includes(youAreOfflineUrl)) {
          if (this._router.url?.includes('reseller')) {
            this._resellerAuthService.logout();
          } else {
            this._authService.logout();
          }

				}
				return throwError(error);
			})
		);
	}
}
