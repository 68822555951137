import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from "../core/services/auth.service";
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
	selector: 'app-redirect',
	standalone: true,
	imports: [
		MatProgressSpinner
	],
	templateUrl: './redirect.component.html',
	styleUrl: './redirect.component.scss'
})
export class RedirectComponent implements OnInit {
	constructor(
		private _router: Router,
    private _authService: AuthService

  ) {}

	public ngOnInit(): void {
    if (this._authService.token) {
      this._router.navigate(['workspace']);
    } else {
      this._router.navigate(['/auth/login']);
    }
  }
}
